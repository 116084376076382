import React from "react";
import logo from "../images/react.png";

const Header = () => {
  return (
    <div className="row  header-row">
      <div className="col-10 col-md-10 offset-md-1 offset-md-right-1 m-auto flex-row">
        <i className="bi bi-recycle mt-2 logo"></i>

        <p className="app-heading">
          <span>Travel</span>
          <span className="bold">Opedia </span>
          <span style={{ fontSize: "2.8rem", color: "white" }}>...</span>
        </p>
      </div>
    </div>
  );
};

export default Header;
