import React, { useState } from "react";

import { useAddDestinationMutation } from "../api/destinationAPI";
const AddDestination = () => {
  const [newCity, setNewCity] = useState("");
  const [newCountry, setNewCountry] = useState("");

  const [addDestination] = useAddDestinationMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    //..Add Destination Logic
    //calling
    addDestination({
      id: parseInt(Math.random(0, 99) * 100),
      city: newCity,
      country: newCountry,
      daysNeeded: parseInt(Math.random() * 10) + 1,
    });
    setNewCity("");
    setNewCountry("");
  };

  return (
    <div className="p-4 border">
      <form action="">
        <div className="row col-8 offset-2 offset-md-2">
          <h4>Enter a new Destination</h4>
          <div className="col-5 p-1">
            <input
              type="text"
              value={newCity}
              placeholder="Enter city..."
              className="form-control"
              onChange={(e) => setNewCity(e.target.value)}
            />
          </div>
          <div className="col-5 p-1">
            <input
              type="text"
              value={newCountry}
              placeholder="Enter country..."
              className="form-control"
              onChange={(e) => setNewCountry(e.target.value)}
            />
          </div>
          <div className="col-2 p-1">
            <button
              onClick={handleSubmit}
              className="btn btn-success form-control"
            >
              Add
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddDestination;
