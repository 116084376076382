import { configureStore } from "@reduxjs/toolkit";

import { destinationAPI } from "../api/destinationAPI";
import { randonDestinationAPI } from "../api/randonDestinationAPI";

export const store = configureStore({
  reducer: {
    [destinationAPI.reducerPath]: destinationAPI.reducer,
    [randonDestinationAPI.reducerPath]: randonDestinationAPI.reducer,
  },
  // ImportantNeed 2 add middleware
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(destinationAPI.middleware)
      .concat(randonDestinationAPI.middleware),
});
