import React from "react";
import { useGetAllDestinationQuery } from "../api/destinationAPI";
import Destination from "./Destination";

const DestinationList = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetAllDestinationQuery();

  let content;
  if (isLoading) {
    content = <p>Loading...</p>;
  } else if (isSuccess) {
    console.log("Sucess ", data);
    content = data.map((destination, index) => {
      return (
        <Destination
          key={destination.id}
          destination={destination}
        ></Destination>
      );
    });
  }
  return <div className="pt-3">{content}</div>;
};

export default DestinationList;
