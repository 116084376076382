import React from "react";

import { useGetRandomDestinationQuery } from "../api/randonDestinationAPI";

const RandomDestination = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetRandomDestinationQuery();

  let content;
  if (isLoading) {
    content = <p>Loading...</p>;
  } else if (isSuccess) {
    content = (
      <div className="text-center border p-3">
        <h4 className="text-success">Random Travel Suggestion</h4>
        <p>
          {data.city}, {data.country}
        </p>
      </div>
    );
  } else if (isError) {
    content = <p>{isError}</p>;
  }
  return <div className="pt-3">{content}</div>;
};

export default RandomDestination;
