import React, { useState } from "react";
import {
  useDeleteDestinationMutation,
  useUpdateDestinationMutation,
} from "../api/destinationAPI";

const Destination = (props) => {
  const [isEditing, setEdit] = useState(false);
  const [editCity, setEditCity] = useState("");
  const [editCountry, setEditCountry] = useState("");

  const [deleteDestination] = useDeleteDestinationMutation();
  const [updateDestination] = useUpdateDestinationMutation();
  const { destination } = props;

  const handleEdit = () => {
    let city = "",
      country = "";
    city = editCity == "" ? destination.city : editCity;
    country = editCountry == "" ? destination.country : editCountry;
    console.log("country :", country);
    updateDestination({
      id: destination.id,
      city: city,
      country: country,
      daysNeeded: destination.daysNeeded,
    });
    setEditCity("");
    setEditCountry("");
    setEdit(false);
  };
  return (
    <div className="row p-1 m-1 mb-2 border-bottom">
      <div className="col-3 col-md-3 offset-md-2 ">
        <div className="text-start text-secondary fs-5 ">
          {isEditing ? (
            <input
              type="text"
              defaultValue={destination.city}
              className="form-control"
              onChange={(e) => setEditCity(e.target.value)}
            />
          ) : (
            <span>{destination.city}</span>
          )}
        </div>
      </div>
      <div className="col-2 offset-md-right-1">
        {isEditing ? (
          <input
            type="text"
            defaultValue={destination.country}
            className="form-control"
            onChange={(e) => setEditCountry(e.target.value)}
          />
        ) : (
          <span className="text-light text-start text-light  fs-5">
            {destination.country}
          </span>
        )}
      </div>
      <div className="col-1">
        <p className="text-info  fs-5 text-warning ">
          {destination.daysNeeded} &nbsp; days.
        </p>
      </div>
      <div className="col-3 offset-1 offset-md-1">
        <div className="d1-flex">
          <div className="d1-flex-inner">
            {!isEditing ? (
              <i
                className="bi bi-pencil-fill btn-warning m-5 p-2"
                title="Edit"
                style={{
                  color: "green",
                  cursor: "pointer",
                  fontSize: "1.35rem",
                }}
                onClick={() => setEdit(true)}
              >
                {" "}
              </i>
            ) : (
              <>
                <i
                  className="bi bi-bookmark-check-fill p-2"
                  title="Save"
                  style={{
                    color: "green",
                    cursor: "pointer",
                    fontSize: "1.35rem",
                  }}
                  onClick={handleEdit}
                ></i>

                <i
                  className="bi bi-x-circle-fill btn-warning p-2"
                  title="Cancel"
                  style={{
                    color: "orange",
                    cursor: "pointer",
                    fontSize: "1.35rem",
                  }}
                  onClick={() => setEdit(false)}
                >
                  {" "}
                </i>
              </>
            )}
          </div>
          <div>
            <i
              className="bi bi-trash3-fill btn-sm btn-format btn-danger"
              title="Delete"
              onClick={() => deleteDestination({ id: destination.id })}
              style={{
                color: "red",
                cursor: "pointer",
                fontSize: "1.35rem",
              }}
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Destination;
