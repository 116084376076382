import React from "react";
import ReactDOM from "react-dom/client";
import Header from "./Layouts/Header";
import "./main.css";
import DestinationIndex from "./components/DestinationIndex";

// import { ApiProvider } from "@reduxjs/toolkit/dist/query/react";

import { Provider } from "react-redux";
import { destinationAPI } from "./api/destinationAPI";
import { store } from "./redux/store";
import RandomDestination from "./components/RandomDestination";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Header></Header>
      <DestinationIndex />
      <RandomDestination />
    </Provider>
  </React.StrictMode>
);
